"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
// gsap-animations.js

// gsap-scroll-linked-animations.js
gsap.registerPlugin(ScrollTrigger);
document.addEventListener("DOMContentLoaded", function () {
  var animationClasses = ["scale-in-center", "slide-in-top", "slide-in-right", "slide-in-bottom", "slide-in-left", "fade-in", "fade-in-fwd", "fade-in-top", "fade-in-right", "fade-in-bottom", "fade-in-left"];
  var selector = animationClasses.map(function (c) {
    return "." + c;
  }).join(", ");
  var animatedElements = document.querySelectorAll(selector);
  animatedElements.forEach(function (element) {
    var animationType = animationClasses.find(function (c) {
      return element.classList.contains(c);
    });
    var fromVars, toVars;
    switch (animationType) {
      case "scale-in-center":
        fromVars = {
          scale: 0,
          opacity: 0
        };
        toVars = {
          scale: 1,
          opacity: 1
        };
        break;
      case "slide-in-top":
        fromVars = {
          y: "-60vh",
          opacity: 0
        };
        toVars = {
          y: 0,
          opacity: 1
        };
        break;
      case "slide-in-right":
        fromVars = {
          x: "100vw",
          opacity: 0
        };
        toVars = {
          x: 0,
          opacity: 1
        };
        break;
      case "slide-in-bottom":
        fromVars = {
          y: "30vh",
          opacity: 0
        };
        toVars = {
          y: 0,
          opacity: 1
        };
        break;
      case "slide-in-left":
        fromVars = {
          x: "-100vw",
          opacity: 0
        };
        toVars = {
          x: 0,
          opacity: 1
        };
        break;
      case "fade-in":
        fromVars = {
          opacity: 0
        };
        toVars = {
          opacity: 1
        };
        break;
      case "fade-in-fwd":
        fromVars = {
          z: -80,
          opacity: 0
        };
        toVars = {
          z: 0,
          opacity: 1
        };
        break;
      case "fade-in-top":
        fromVars = {
          y: "-50vh",
          opacity: 0
        };
        toVars = {
          y: 0,
          opacity: 1
        };
        break;
      case "fade-in-right":
        fromVars = {
          x: "100vw",
          opacity: 0
        };
        toVars = {
          x: 0,
          opacity: 1
        };
        break;
      case "fade-in-bottom":
        fromVars = {
          y: "50vh",
          opacity: 0
        };
        toVars = {
          y: 0,
          opacity: 1
        };
        break;
      case "fade-in-left":
        fromVars = {
          x: "-100vw",
          opacity: 0
        };
        toVars = {
          x: 0,
          opacity: 1
        };
        break;
      default:
        fromVars = {
          opacity: 0
        };
        toVars = {
          opacity: 1
        };
    }
    gsap.fromTo(element, fromVars, _objectSpread(_objectSpread({}, toVars), {}, {
      ease: "power2.out",
      scrollTrigger: {
        trigger: element,
        start: "top bottom",
        // Starts when the top of the element hits the bottom of the viewport
        end: "top 50%",
        // Ends when the bottom of the element is 40% up from the bottom of the viewport
        scrub: 0.5,
        // Added a small delay for smoother scrolling
        markers: false // Set to true for debugging
      }
    }));
  });
});